// https://stackoverflow.com/questions/60182031/src-zone-flags-ts-is-missing-from-the-typescript-compilation-after-upgrading-to
// https://medium.com/@johnathanesanders/resolving-issues-with-zone-flags-after-upgrading-to-ionic-5-with-angular-9-db155974123c
// https://ionicframework.com/docs/troubleshooting/runtime#angular-change-detection
(window as any).__Zone_disable_customElements = true;
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['touchstart', 'pointerdown', 'transitionend',
    'touchmove', 'touchend', 'webkitTransitionEnd', 'resize', 'scroll'];
(window as any).__Zone_disable_blocking = true;
(window as any).__Zone_disable_geolocation = true;
(window as any).__Zone_disable_toString = true;
(window as any).__Zone_disable_on_property = true;
(window as any).__Zone_disable_Error = true;
